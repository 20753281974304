import React from 'react';

class Board extends React.Component {
    renderSquare(i) {
        return (
            <Square
                key={i}
                value={this.props.squarePieces[i]}
                highlight={this.props.squareHighlights[i]}
                onClick={() => this.props.onClick(i)}
            />
        );
    }

    createSquares() {
        let rows = [];
        for (var row = 0; row < 3; row++) {
            let squares = [];
            for (var col = 0; col < 3; col++) {
                squares.push(this.renderSquare(3 * row + col));
            }
            rows.push(<div key={row} className="board-row">{squares}</div>);
        }
        return rows;
    }

    render() {
        return (
            <div>
                {this.createSquares()}
            </div>
            // <div>
            //   {
            //     [0, 1, 2].map((row) => {
            //       return <div key={row} className="board-row">
            //         {
            //           [0, 1, 2].map((col) =>
            //             this.renderSquare(3 * row + col)
            //           )
            //         }
            //       </div>
            //     })
            //   }
            // </div>
        );
    }
}

function Square(props) {
    return (
        <button
            className="square"
            style={props.highlight ? { backgroundColor: 'yellow' } : {}}
            onClick={props.onClick}
        >
            {props.value}
        </button>
    );
}

export default Board