/* global globalThis */
import freeGlobal from './freeGlobal.js'

/** Detect free variable `globalThis` */
const freeGlobalThis = typeof globalThis === 'object' && globalThis !== null && globalThis.Object === Object && globalThis

/** Detect free variable `self`. */
var self = this; // https://stackoverflow.com/questions/44292520/unexpected-use-of-self-no-restricted-globals-react
const freeSelf = typeof self === 'object' && self !== null && self.Object === Object && self

/** Used as a reference to the global object. */
const root = freeGlobalThis || freeGlobal || freeSelf

export default root
