import React from 'react';
import Board from './Board.js';
import cloneDeep from './lodash-clone/cloneDeep.js';

import firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCqp4BKx_5U7Ygygg1LWTsHua-S0-5o_Hk",
    // authDomain: "tic-tac-toe-4d8c3.firebaseapp.com",
    authDomain: "tic-tac-toe.szebert.com",
    projectId: "tic-tac-toe-4d8c3",
    storageBucket: "tic-tac-toe-4d8c3.appspot.com",
    messagingSenderId: "467816281369",
    appId: "1:467816281369:web:56ffac7afcc8e6c1238188",
    measurementId: "G-KC5PELQWF3"
};

firebase.initializeApp(firebaseConfig);
   
class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [{
                squares: this.createSquares()
            }],
            stepNumber: 0,
            xIsNext: true,
            ascending: true
        };
    }

    createSquares() {
        let squares = [];
        let i = 0;
        for (let r = 1; r < 4; r++) {
            for (let c = 1; c < 4; c++) {
                let square = {
                    index: i,
                    piece: null,
                    move: null,
                    highlight: false,
                    loc: {
                        row: r,
                        col: c
                    }
                }
                squares.push(square);
                i++;
            }
        }
        return squares;
    }

    handleClick(i) {
        const history = this.state.history.slice(0, this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = cloneDeep(current.squares);
        if (calculateWinner(squares) || squares[i].piece) {
            return;
        }
        squares[i].piece = this.state.xIsNext ? 'X' : 'O';
        squares[i].move = history.length;
        this.setState({
            history: history.concat([{
                squares: squares
            }]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext
        });
    }

    jumpTo(move) {
        this.setState({
            stepNumber: move,
            xIsNext: (move % 2) === 0
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);

        const moves = history.map((step, index) => {
            const desc = index ?
                'Go to move #' + index :
                'Go to game start';
            let loc;
            if (index) {
                const location = this.getSquareLoc(step.squares, index);
                loc = 'Location: (' + location.col + ',' + location.row + ')';
            } else {
                loc = '';
            }
            return (
                <li
                    key={index}
                    style={this.state.stepNumber === index ?
                        { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                >
                    <button
                        onClick={() => this.jumpTo(index)}
                        style={this.state.stepNumber === index ?
                            { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                    >
                        {desc}
                        <br />
                        {loc}
                    </button>
                </li>
            );
        });

        let status;
        if (winner) {
            status = 'Winner: ' + winner;
        } else if (this.state.stepNumber === 9) {
            status = 'Draw';
        } else {
            status = 'Next player: ' + (this.state.xIsNext ? 'X' : 'O');
        }

        const toggle = this.state.ascending ? 'ascending' : 'descending';

        return (
            <div className="game">
                <div className="game-board">
                    <Board
                        squarePieces={this.getSquarePieces()}
                        squareHighlights={this.getSquareHighlights()}
                        onClick={(i) => this.handleClick(i)}
                    />
                </div>
                <div className="game-info">
                    <div>{status}</div>
                    <button
                        onClick={() => this.toggle()}
                    >
                        {toggle}
                    </button>
                    <ol>{this.state.ascending ? moves : moves.reverse()}</ol>
                </div>
            </div>
        );
    }

    toggle() {
        this.setState({
            ascending: !this.state.ascending
        });
    }

    getSquareLoc(squares, move) {
        for (let index = 0; index < squares.length; index++) {
            const element = squares[index];
            if (element.move === move) {
                return element.loc;
            }
        }
        return { row: null, col: null };
    }

    getSquarePieces() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        let pieces = [];
        for (let index = 0; index < current.squares.length; index++) {
            const piece = current.squares[index].piece;
            pieces.push(piece);
        }
        return pieces;
    }

    getSquareHighlights() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        let highlights = [];
        for (let index = 0; index < current.squares.length; index++) {
            const highlight = current.squares[index].highlight;
            highlights.push(highlight);
        }
        return highlights;
    }
}

function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a].piece &&
            squares[a].piece === squares[b].piece &&
            squares[a].piece === squares[c].piece) {
            highlightWinningTiles(squares, lines[i]);
            return squares[a].piece;
        }
    }
    return null;
}

function highlightWinningTiles(squares, line) {
    for (let index = 0; index < line.length; index++) {
        const i = line[index];
        squares[i].highlight = true;
    }
}

export default Game